import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from '../components/layout'
import Seo from '../components/Seo';
import StructuredData from '../components/structured-data'
import HubspotForm from '../components/hubspot/HubspotForm'

function ContactUsPage({ data }) {
  const { page, hero } = data
  const [formStatus, setFormStatus] = useState('Support')

  return (
    <Layout>
      <Seo post={page} />
      <StructuredData pageType="webPage" post={page} />
      <div className="py-10 lg:py-20 relative">
        <div className="container relative z-30 text-white">
          <h1 className="text-white mb-2 text-3xl lg:text-4xl">
            How Can We Help?
          </h1>
          <p className="text-2xl lg:text-3xl font-semibold mb-6">
            Call, Chat, Email, Any Way You Choose
          </p>
        </div>
        <div
          className="absolute w-full h-full top-0 left-0 z-20"
          style={{
            backgroundImage: `linear-gradient(90deg, rgba(100,55,91,0.89) 0%, rgba(46,60,75,0.31) 100%)`,
          }}
          role="presentation"
        ></div>
        <GatsbyImage
          image={hero.childImageSharp.gatsbyImageData}
          alt="Liquid Web employees"
          className="w-full h-full left-0 top-0 z-10"
          imgStyle={{ objectPosition: `center top` }}
          style={{ position: `absolute` }} />
      </div>

      <div className="py-10 container grid gap-10 lg:grid-cols-2">
        <div>
          <h2 className="text-2xl lg:text-3xl mb-2">
            When You Need us, We&apos;re Here!
          </h2>
          <p className="mb-4">
            We’re called The Most Helpful Humans In Hosting® for a reason: we’re
            responsive, highly trained, and dedicated in ways a machine simply
            can’t be.
          </p>
          <p className="mb-4">
            We’ll help you choose the right solution. We’re here to help you
            migrate your data and get started. Need a multi-server solution? We
            have architects who can help you design a custom solution. If you
            need support, work with one of our nearly 250+ Red Hat Linux and
            Windows Certified technicians quickly and on your first contact.
          </p>
          <p>So, how can we help?</p>
        </div>
        <div className="w-5/6">
          <label htmlFor="type" className="block">
            <span className="font-bold">Type of Request</span>
            <select
              name="type"
              id="type"
              className="form-select w-full mt-2 block"
              onChange={() => {
                setFormStatus(event.target.value)
              }}
            >
              {[`Support`, `Abuse`, `DMCA`, `CCPA`, `Sales`].map(type => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </label>
          {formStatus === 'Support' && (
            <a
              id="Support-btn"
              className="btn btn-primary mt-4 w-auto p-4"
              href="https://help.liquidweb.com/s/contactsupport"
            >
              Create a Ticket
            </a>
          )}
          {formStatus === 'Abuse' && (
            <Link
              id="Abuse-btn"
              className="btn btn-primary mt-4 w-auto p-4"
              to="/contact-us/abuse/"
            >
              Submit an Abuse Claim
            </Link>
          )}
          {formStatus === 'DMCA' && (
            <Link
              id="DMCA-btn"
              className="btn btn-primary mt-4 w-auto p-4"
              to="/about-us/policies/dmca/#dmca-form"
            >
              Submit a Copyright Infringement Claim
            </Link>
          )}
          {formStatus === 'CCPA' && (
            <Link
              id="CCPA-btn"
              className="btn btn-primary mt-4 w-auto p-4"
              to="/about-us/policies/ccpa/#ccpa-form"
            >
              Submit a CCPA Request
            </Link>
          )}
          {formStatus === 'Sales' && (
            <HubspotForm
              standardFormID="eae457b0-e81e-41c9-8178-526ad2ca6bf9"
              cssClass="lw-hubspot-form"
              className="mt-5"
              analyticsEventLabel="Contact-Us-W2L"
            />
          )}
        </div>
      </div>

      <div className="py-10" style={{ backgroundColor: `#f4f4f4` }}>
        <div className="container text-center">
          <h2 className="mb-2 text-2xl lg:text-3xl">Around-the-clock.</h2>
          <p className="mb-6 text-xl lg:text-2xl font-semibold">
            800.580.4985 (1.517.322.0434)
          </p>
          <ul className="grid md:grid-cols-3 gap-10 md:gap-24">
            {[
              {
                f: `icon54-business-man`,
                t: `Help Picking the Perfect Plan?`,
                e: `Solutions: Phone Option 1`,
              },
              {
                f: `icon54-profile-lightbulb`,
                t: `Technical Help With Your Account?`,
                e: `Support: Phone Option 2`,
              },
              {
                f: `icon54-chat-user`,
                t: `Questions on Billing?`,
                e: `Billing: Phone Option 3`,
              },
            ].map(item => (
              <li key={item.t}>
                <div
                  className="w-12 h-12 mx-auto mb-4"
                  style={{ color: `#03a0ca`, fontSize: '48px' }}
                >
                  <div className={item.f}></div>
                </div>
                <div className="mb-2">{item.t}</div>
                <div className="font-bold">{item.e}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="container py-20">
        <h2 className="text-3xl mb-10">Additional Helpful Humans</h2>
        <ul className="grid md:grid-cols-2 gap-10">
          {[
            {
              dept: `Media Relations`,
              p: `1-800-580-4985`,
              e: `media@liquidweb.com`,
            },
            {
              dept: `Affiliate Program`,
              p: `1-800-580-4985 ext. 2653`,
              e: `affiliateprogram@liquidweb.com`,
            },
            {
              dept: `Partner Programs`,
              p: `1-866-210-4185`,
              e: `partner-team@liquidweb.com`,
            },
          ].map(item => (
            <li key={item.dept}>
              <div className="font-bold">{item.dept}</div>
              {item.p && <div>Phone: {item.p}</div>}
              {item.e && (
                <div>
                  Email:{' '}
                  <a href={`mailto:${item.e}`} className="text-blue-700">
                    {item.e}
                  </a>
                </div>
              )}
            </li>
          ))}
          <li>
            <div className="font-bold">Corporate Address</div>
            Liquid Web Inc <br />
            2703 Ena Dr. <br />
            Lansing, MI 48917, US
          </li>
          <li>
            <a href="/contact-us/abuse/" className="text-blue-700">
              Report Abuse
            </a>
            <br />
            <a
              href="/about-us/policies/dmca/#dmca-form"
              className="text-blue-700"
            >
              Report DMCA Violation
            </a>
          </li>
          <li>
            <a
              href="https://liquidweb.uservoice.com/forums/100399-general"
              className="text-blue-700"
            >
              Submit Feedback
            </a>
          </li>
        </ul>
      </div>

      <div className="text-white" style={{ backgroundColor: `#64375b` }}>
        <div className="container py-4 text-center">
          <Link to="/blog/" className="text-white hover:text-white">
            Visit Our Liquid Web Blog
          </Link>
        </div>
      </div>
    </Layout>
  );
}

ContactUsPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

export default ContactUsPage

export const query = graphql`{
  page: wpPage(slug: {eq: "contact-us"}) {
    title
    date
    modified
    uri
    slug
    chatDepartment
    seo {
      ...postSeoFields
    }
  }
  hero: file(relativePath: {eq: "contact/hero.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
}
`
